<template>
  <div class="mUi">
    <Title />
    <LocationMenu />
    <!-- <Footer /> -->
  </div>
</template>
<script>
// import Footer from "./Footer.vue";
import Title from "./Title.vue";
import LocationMenu from "./LocationMenu.vue";

export default {
  name: "MobileUi",
  data() {
    return {
      strings: this._vaData.strings,
      locationTitle: false
    };
  },
  props: {},
  computed: {},
  methods: {},
  created() {},
  components: {
    Title,
    LocationMenu
    // Footer
  }
};
</script>
<style lang="scss" scoped>
body {
  .mUi {
    display: none;
    height: 100%;
    overflow: hidden;
  }
  &.is-mobile {
    .mUi {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
