<template lang="html">
  <header
    class="header"
    :class="settings.header_options.header_bar ? 'add_headerBar' : ''"
  >
    <a
      class="logo"
      :title="strings.app_title"
      v-if="settings.logo"
      @click="goHome"
    >
      <img :src="_vaData.apiBase + settings.logo" :alt="strings.app_title" />
    </a>
    <Title class="header_title" />

    <LanguageSwitcher class="languageSwitcher__header" />
    <div>
      <h3 class="app_title">
        {{ strings.app_title }}
      </h3>
    </div>
    <button class="menu-btn" uk-toggle="target: #offcanvas">
      <div></div>
      <div></div>
      <div></div>
    </button>
  </header>
</template>

<script>
import Title from "./Title.vue";
import { bus } from "../main";
import LanguageSwitcher from "./LanguageSwitcher.vue";

export default {
  data: function() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings
    };
  },
  methods: {
    goHome() {
      bus.$emit("go_to_location", this.settings.start_location);
    }
  },
  components: {
    Title,
    LanguageSwitcher
  }
};
</script>

<style lang="scss" scoped>
body {
  &.is-mobile {
    .app_title {
      display: none;
      overflow: hidden;
    }
    .menu-btn {
      transform: translateY(0%);
      right: 10px;
      top: 10px;
    }
    .languageSwitcher__header {
      display: none;
    }
    .header {
      padding: 15px;
      justify-content: space-between;
      position: static;
    }
    .header_title {
      display: none;
    }
  }
}
.header {
  overflow: hidden;
  z-index: 2;
  font-size: 14px;
  padding: 10px 30px;
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  gap: 2vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  a,
  button {
    pointer-events: all;
  }
}
.add_headerBar {
  background-color: var(--header_bar__color);
}

.logo {
  pointer-events: all;
  min-width: var(--logo_width);
  max-width: var(--logo_width);

  img {
    width: 100%;
    display: block;

    // transform: translateY(-50%);
    // top: 50%;
  }

  @media (max-width: $breakpoint-large) {
    min-width: calc(var(--logo_width) / 2);
    max-width: calc(var(--logo_width) / 2);
  }
}
.app_title {
  right: 100px;
  font-weight: bold;
  transform: translateY(-50%);
  top: 50%;
  margin-top: 0px;
  position: absolute;
  float: right;
  text-transform: var(--font_title_transform);
  color: var(--font_color__title);
  font-size: var(--title_size);
}
.menu-btn {
  height: 36px;
  width: 36px;
  transform: translateY(-50%);
  top: 50%;
  margin-top: 0px;
  position: absolute;
  right: 30px;
  flex-shrink: 0;
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;
  cursor: pointer;
  transition: background-color 0.2s;
  & > div {
    height: 2px;
    width: 90%;
    background: #000;
    transition: background-color 0.2s;
  }

  &:hover {
    & > div {
      background: var(--font_color__title);
    }
  }

  .uk-offcanvas-container & {
    & > div {
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      // z-index: ;
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
.languageSwitcher__header {
  right: 260px;
  transform: translateY(-50%);
  top: 50%;
  margin-top: 0px;
  position: absolute;
  float: right;
}
</style>
